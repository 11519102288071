@tailwind base;
@tailwind components;
@tailwind utilities;

/* Include this in your main CSS file */
.toggle-checkbox:checked {
    @apply: right-0 border-blue-500;
  }
  
.toggle-checkbox {
    @apply: left-0 border-gray-400;
    transition: right 0.2s ease-in-out;
}

.toggle-label {
    @apply: block h-6 w-6 rounded-full bg-white shadow inset-y-0 left-0;
    transition: left 0.2s ease-in-out;
}